import React from "react";
import {
  confirmDialog,
  createUseStyles,
  useAccessToken,
  useDrag,
  Button,
  Card,
  Dialog,
  Divider,
  InputText,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import {
  deleteWordFromPile,
  updateWordInDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";
import DraggableCardFooter from "./DraggableCardFooter";

const useStyles = createUseStyles({
  root: {},
  card: {
    height: "10rem",
  },
});

const DraggableCard = ({
  pileItem,
  pileIndex,
  pileName,
  cardIndex,
  color,
  pileId,
  updateParentView,
  parentViewUpdater,
}) => {
  const classes = useStyles();

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "pileItem",
    item: { pileItem, pileIndex, pileName, cardIndex, pileId, noPreview: true },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const { word, meaning, _id: wordId } = pileItem;

  return (
    <div ref={drag} style={{ cursor: "move" }}>
      <Card
        key={pileIndex}
        title={word}
        subTitle={meaning}
        pt={{
          root: { className: "m-2 h-full w-full" },
          body: { className: "h-full" },
        }}
        style={{
          background: color,
          width: "24rem",
          height: "15rem",
        }}
        footer={
          <DraggableCardFooter
            pileId={pileId}
            wordId={wordId}
            word={word}
            meaning={meaning}
            updateParentView={updateParentView}
            parentViewUpdater={parentViewUpdater}
          />
        }
      />
    </div>
  );
};

export default DraggableCard;
