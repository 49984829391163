import React from "react";

import {
  deleteWordFromDictionary,
  deleteDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  confirmDialog,
  useNavigate,
  Card,
  ConfirmDialog,
  Button,
  InputText,
  Divider,
  Dialog,
  Toast,
  Skeleton,
  ROUTES,
  Paginator,
  useAccessToken,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import UpdateWordPopUp from "./molecules/UpdateWordPopUp";
import AddWordPopUp from "./molecules/AddWordPopUp";
import DictionaryItem from "./DictionaryItem";

const DictionaryBrowserMain = ({
  updateParentView,
  parentViewUpdater,
  dictionary,
}) => {
  const { t } = useTranslation();
  const [viewUpdater, updateView] = React.useState<boolean>();

  const toast = React.useRef<Toast>(null);

  const navigate = useNavigate();

  const token = useAccessToken();

  const [showAddWordPopup, setShowAddWordPopup] = React.useState(false);

  if (!dictionary) {
    return (
      <div className="flex flex-column w-9 h-full">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </div>
    );
  }

  function clickStudyDictionary() {
    navigate(ROUTES.VOCABULARY.DICTIONARY.STUDY(dictionary._id));
  }

  function clickDeleteDictionary() {
    confirmDialog({
      message: t("vocabulary.titles.delete_dictionary", {
        dictionaryName: dictionary.label,
      }),
      header: t("vocabulary.titles.delete_dictionary"),
      icon: "pi pi-exclamation-triangle",
      acceptLabel: t("texts.yes"),
      rejectLabel: t("texts.no"),
      accept: async () => {
        const response = await deleteDictionary(token, {
          dictionaryId: dictionary._id,
        });

        if (typeof response === "object") {
          const isSuccess = response.status === 200;
          const severity = isSuccess ? "success" : "error";
          const summary = isSuccess
            ? t("vocabulary.toasts.summaries.dictionary_delete_success")
            : t("vocabulary.toasts.summaries.dictionary_delete_fail");

          toast.current.show({
            severity,
            summary,
            detail: response.message,
          });

          updateView(!viewUpdater);
          updateParentView(!parentViewUpdater);
        } else {
          toast.current.show({
            severity: "error",
            summary: t("vocabulary.toasts.summaries.dictionary_delete_fail"),
            detail: t("vocabulary.toasts.details.dictionary_delete_fail"),
          });
        }
      },
    });
  }

  return (
    <>
      <Card
        title={dictionary.label}
        subTitle={dictionary.type}
        pt={{
          root: { className: "h-full w-full" },
          body: { className: "h-full w-full" },
          content: {
            className:
              "w-full overflow-scroll flex flex-wrap justify-content-center",
            style: { height: "calc(100% - 8rem)" },
          },
          footer: { className: "flex justify-content-center" },
        }}
        footer={
          <>
            <Button
              label={t("vocabulary.buttons.add_item")}
              onClick={() => {
                setShowAddWordPopup(true);
              }}
            />
            <Button
              label={t("vocabulary.buttons.study")}
              className="ml-3"
              onClick={clickStudyDictionary}
            />
            <Button
              label={t("vocabulary.buttons.delete_dictionary")}
              className="ml-3 p-button-danger"
              onClick={clickDeleteDictionary}
            />
          </>
        }
      >
        <Paginator
          key={viewUpdater}
          items={dictionary.items}
          mapFunction={(dictionaryItem, index) => {
            return (
              <DictionaryItem
                dictionaryId={dictionary._id}
                dictionaryItem={dictionaryItem}
                key={index}
                parentViewUpdater={viewUpdater}
                updateParentView={updateView}
                toast={toast}
              />
            );
          }}
        />
      </Card>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        header={t("vocabulary.titles.add_to_dictionary")}
        visible={showAddWordPopup}
        style={{
          width: "50vw",
        }}
        onHide={() => setShowAddWordPopup(false)}
      >
        <AddWordPopUp
          dictionaryId={dictionary._id}
          updateParentView={updateView}
          parentViewUpdater={viewUpdater}
        />
      </Dialog>
    </>
  );
};

export default DictionaryBrowserMain;
