import React from "react";
import {
  useParams,
  // @ts-ignore
} from "@projectdiction/common-react";
import DictionaryCopierTemplate from "./DictionaryCopierTemplate";

export default function DictionaryCopier() {
  const { id } = useParams();
  return <DictionaryCopierTemplate dictionaryId={id} />;
}
