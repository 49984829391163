import React from "react";

import {
  createUseStyles,
  confirmDialog,
  Card,
  Button,
  InputText,
  Divider,
  Dialog,
  Toast,
  Skeleton,
  ROUTES,
  useAccessToken,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import useStyles from "./UpdateWordPopUp.style";
import {
  updateWordInDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

const UpdateWordPopUp = ({
  dictionaryId,
  setShowUpdateWordPopup,
  wordInput,
  setWordInput,
  meaningInput,
  setMeaningInput,
  currentWordId,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const toast = React.useRef<Toast>(null);

  const token = useAccessToken();

  return (
    <div>
      <div className="fluid">
        <InputText
          value={wordInput}
          onChange={(e) => setWordInput(e.target.value)}
          placeholder={t("vocabulary.inputs.word")}
        />
        <Divider />
        <InputText
          value={meaningInput}
          onChange={(e) => setMeaningInput(e.target.value)}
          placeholder={t("vocabulary.inputs.meaning")}
        />
      </div>
      <Divider />
      <div className={classes.buttonRoot}>
        <Button
          label={t("vocabulary.buttons.update_word")}
          onClick={async () => {
            const response = await updateWordInDictionary(token, {
              dictionaryId: dictionaryId,
              wordId: currentWordId,
              word: wordInput,
              meaning: meaningInput,
            });

            if (typeof response === "object") {
              const isSuccess = response.status === 200;
              const severity = isSuccess ? "success" : "error";
              const summary = isSuccess
                ? t("vocabulary.toasts.summaries.word_update_success")
                : t("vocabulary.toasts.summaries.word_update_fail");

              toast.current.show({
                severity,
                summary,
                detail: response.message,
              });

              setWordInput("");
              setMeaningInput("");
              setShowUpdateWordPopup(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: t("vocabulary.toasts.summaries.word_update_fail"),
                detail: t("vocabulary.toasts.details.word_update_fail"),
              });
            }
          }}
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default UpdateWordPopUp;
