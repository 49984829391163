import React from "react";

import {
  useParams,
  useNavigate,
  useUserPreferences,
  createUseStyles,
  Button,
  Card,
  Skeleton,
  Dialog,
  Toast,
  ROUTES,
  useAccessToken,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getDictionary,
  getAllDictionaries,
  getPersonalDictionaryId,
  // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";
import NewDictionaryPopUp from "./molecules/NewDictionaryPopUp";

const useStyles = createUseStyles({
  root: {},
  dictionariesCard: {
    "& .p-card-body": {
      height: "100%",
    },
    "& .p-card-content": {
      height: "80%",
    },
  },
});

export default function DictionaryBrowserNavSidebar({
  dictionaryId,
  updateParentView,
  parentViewUpdater,
}) {
  const classes = useStyles();

  const [data, setData] = React.useState<any>();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [showPopup, setShowPopup] = React.useState(false);

  const toast = React.useRef<Toast>();

  const token = useAccessToken();

  const userPreferences = useUserPreferences();

  React.useEffect(() => {
    if (!userPreferences) return;
    const fetch = async () => {
      let response = await getAllDictionaries(
        token,
        userPreferences.languages.studyLanguage,
        userPreferences.languages.knownLanguage
      );
      const dictionaries = response.data.dictionaries;

      response = await getPersonalDictionaryId(token);
      const personalDictionaryId = response.data.personalDictionaryId;

      response = await getDictionary(token, {
        dictionaryId: personalDictionaryId,
      });

      const personalDictionary = response.data.dictionary;

      dictionaries.unshift(personalDictionary);

      setData(dictionaries);
    };
    fetch();
  }, [userPreferences, token, parentViewUpdater]);

  if (!data) {
    return (
      <Card
        title={t("vocabulary.titles.dictionaries")}
        style={{ height: "100%" }}
      >
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </Card>
    );
  }

  const _Dialog = (
    <Dialog
      header={t("vocabulary.titles.new_dictionary")}
      visible={showPopup}
      style={{
        width: "50vw",
      }}
      onHide={() => setShowPopup(false)}
    >
      <NewDictionaryPopUp
        updateParentView={updateParentView}
        parentViewUpdater={parentViewUpdater}
      />
    </Dialog>
  );

  function mapDictionaryListToNavigationList(navItem, index) {
    const NavItem = (
      <Button
        key={index}
        label={navItem.label}
        className={`w-12 mt-2 p-button-text p-button-outlined
         ${navItem._id !== dictionaryId && "p-button-secondary"}`}
        onClick={() => {
          navigate(ROUTES.VOCABULARY.DICTIONARY.HOME(navItem._id));
        }}
      />
    );

    return NavItem;
  }

  const NavGrid = (
    <div className="grid">{data.map(mapDictionaryListToNavigationList)}</div>
  );

  const ButtonNewDictionary = (
    <Button
      label={t("vocabulary.buttons.new_dictionary")}
      onClick={() => {
        setShowPopup(true);
      }}
    />
  );
  const Footer = (
    <div className="flex justify-content-center">{ButtonNewDictionary}</div>
  );

  const _Toast = <Toast ref={toast} />;

  return (
    <Card
      title={t("vocabulary.titles.dictionaries")}
      style={{ height: "100%" }}
      className={classes.dictionariesCard}
      footer={Footer}
    >
      {_Dialog}
      {NavGrid}
      {_Toast}
    </Card>
  );
}
