import React from "react";

import {
  createUseStyles,
  useParams,
  useAccessToken,
  Card,
  DndProvider,
  HTML5Backend,
  TouchBackend,
  Skeleton,
  Toast,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getDictionary,
  getPiles,
  // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";

import StudyCardFooter from "./StudyCardFooter";
import PileCard from "../PileCard/PileCard";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
    "& .p-card-content": {
      "flex-wrap": "wrap",
    },
  },
});

const StudyCard = () => {
  const classes = useStyles();

  const { id } = useParams();

  const [dictionary, setDictionary] = React.useState<any>();

  const [piles, setPiles] = React.useState<any>();

  const [currentIndices, _setCurrentIndices] = React.useState(
    piles && Object.keys(piles).map ? Object.keys(piles).map((x) => 0) : []
  );

  const [viewUpdater, _updateView] = React.useState<boolean>();

  const { t } = useTranslation();

  const token = useAccessToken();

  const toast = React.useRef<Toast>();

  const updateView = (val) => {
    _updateView(val);
    setPiles(null);
  };

  React.useEffect(() => {
    if (id) {
      getDictionary(token, { dictionaryId: id })
        .then((response: any) => {
          const dictionary = response.data.dictionary;

          getPiles(token, { dictionaryId: dictionary._id })
            .then((response: any) => {
              setPiles(response.data.piles);
            })
            .catch((error) => console.log("error", error));

          setDictionary(dictionary);
        })
        .catch((error) => console.log("error", error));
    }
  }, [id, viewUpdater]);

  if (!dictionary || !piles) {
    return (
      <div className="flex flex-column w-9 h-full">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </div>
    );
  }

  // https://stackoverflow.com/a/52855084

  const touch = window.matchMedia("(pointer: coarse)").matches;

  return (
    <DndProvider backend={touch ? TouchBackend : HTML5Backend}>
      <Card
        title={t("vocabulary.titles.study_vocabulary")}
        subTitle={dictionary.label}
        pt={{
          root: { className: "h-full w-full" },
          body: { className: "h-full" },
          title: {
            className: "w-full flex align-items-center justify-content-center",
            style: { height: "1.5rem" },
          },
          subTitle: {
            className: "w-full flex align-items-center justify-content-center",
            style: { height: "0.5rem" },
          },
          content: {
            className:
              "flex flex-wrap align-items-center justify-content-center overflow-auto pt-2",
            style: { height: "calc(100% - 6rem)" },
          },
          footer: { style: { height: "8rem" } },
        }}
        footer={
          <StudyCardFooter
            dictionaryId={dictionary._id}
            piles={piles}
            setCurrentIndices={_setCurrentIndices}
            toast={toast}
            updateParentView={updateView}
            parentViewUpdater={viewUpdater}
          />
        }
      >
        {piles &&
          piles.map((pile, index) => {
            return (
              <div
                key={index}
                className="m-4"
                style={{
                  height: "30rem",
                  width: "32rem",
                }}
              >
                <PileCard
                  key={index}
                  name={pile.label}
                  pileId={pile._id}
                  cardIndex={index}
                  parentViewUpdater={viewUpdater}
                  updateParentView={updateView}
                />
              </div>
            );
          })}

        <Toast ref={toast} />
      </Card>
    </DndProvider>
  );
};

export default StudyCard;
