import React from "react";

import DictionaryBrowser from "./DictionaryBrowser/DictionaryBrowser";
import VocabularyMainGrid from "./VocabularyMainGrid";

import {
  useNavigate,
  DictionaryCategories,
  ROUTES,
  Routes,
  Route,
  // @ts-ignore
} from "@projectdiction/common-react";
import StudyCard from "./Study/StudyCard/StudyCard";
import DictionaryCopier from "./Copier/DictionaryCopier";

export function App() {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path={ROUTES.VOCABULARY.HOME} element={<VocabularyMainGrid />} />
      <Route
        path={ROUTES.VOCABULARY.DICTIONARY.HOME()}
        element={<DictionaryBrowser />}
      />
      <Route
        path={ROUTES.VOCABULARY.DICTIONARY.HOME(":id")}
        element={<DictionaryBrowser />}
      />
      <Route
        path={ROUTES.VOCABULARY.DICTIONARY.STUDY(":id")}
        element={<StudyCard />}
      />
      <Route
        path={ROUTES.VOCABULARY.DICTIONARY.COPY(":id")}
        element={<DictionaryCopier />}
      />
      <Route
        path={ROUTES.VOCABULARY.CATEGORIES.HOME}
        element={
          <DictionaryCategories
            onSingleItemClick={(dictionary) =>
              navigate(ROUTES.VOCABULARY.DICTIONARY.COPY(dictionary._id))
            }
          />
        }
      />
    </Routes>
  );
}
