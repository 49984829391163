import React from "react";

import {
  createUseStyles,
  saveToLocalStorage,
  useNavigate,
  useAccessToken,
  Button,
  Card,
  Dialog,
  Divider,
  InputText,
  Toast,
  ROUTES,
  LOCAL_STORAGE_ITEMS,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  newPile,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function NewPileDialog({
  showPopup,
  setShowPopup,
  pileNameInput,
  setPileNameInput,
  dictionaryId,
  setCurrentIndices,
  updateParentView,
  parentViewUpdater,
  toast,
}) {
  const { t } = useTranslation();
  const token = useAccessToken();

  return (
    <Dialog
      header={t("vocabulary.titles.add_new_pile")}
      visible={showPopup}
      style={{ width: "50vw" }}
      onHide={() => setShowPopup(false)}
    >
      <div>
        <div className="fluid">
          <InputText
            value={pileNameInput}
            onChange={(e) => setPileNameInput(e.target.value)}
            placeholder={t("vocabulary.inputs.pile_name")}
          />
        </div>
        <Divider />
        <div>
          <Button
            label={t("vocabulary.buttons.add_pile")}
            onClick={async () => {
              const data = await newPile(token, {
                dictionaryId,
                label: pileNameInput,
              });

              if (data.status === 400) {
                toast.current.show({
                  severity: "error",
                  summary: t("vocabulary.toasts.summaries.pile_create_fail"),
                  detail: t("vocabulary.toasts.details.pile_name_unique"),
                });
              } else {
                updateParentView(!parentViewUpdater);
              }

              setCurrentIndices((prev: any) => prev.concat(0));

              setShowPopup(false);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
