import React from "react";

import {
  Button,
  InputText,
  Divider,
  Toast,
  useAccessToken,
  useUserPreferences,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  addWordToDictionary,
  translate,
  // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";
import useStyles from "./AddWordPopUp.style";

const AddWordPopUp = ({
  dictionaryId,
  updateParentView,
  parentViewUpdater,
}) => {
  const [wordInput, setWordInput] = React.useState("");
  const [meaningInput, setMeaningInput] = React.useState("");

  const { t } = useTranslation();

  const classes = useStyles();

  const toast = React.useRef<Toast>(null);

  const token = useAccessToken();

  const userPreferences = useUserPreferences();

  React.useEffect(() => {
    async function fetchTranslations() {
      const response = await translate(
        wordInput,
        userPreferences?.languages?.studyLanguage,
        userPreferences?.languages?.knownLanguage
      );

      setMeaningInput(response.translation);
    }

    if (wordInput) fetchTranslations();
  }, [wordInput, token, userPreferences]);

  return (
    <>
      <div>
        <div className="fluid">
          <InputText
            value={wordInput}
            onChange={(e) => setWordInput(e.target.value)}
            placeholder={t("vocabulary.inputs.word")}
          />
          <Divider />
          <InputText
            value={meaningInput}
            onChange={(e) => setMeaningInput(e.target.value)}
            placeholder={t("vocabulary.inputs.meaning")}
          />
        </div>
        <Divider />
        <div className={classes.buttonRoot}>
          <Button
            label={t("vocabulary.buttons.add_dictionary")}
            onClick={async () => {
              console.log("add word to dictionary token", token);
              try {
                const response = await addWordToDictionary(token, {
                  dictionaryId,
                  word: wordInput,
                  meaning: meaningInput,
                });

                console.log(response);
                if (typeof response === "object") {
                  const isSuccess = response.status === 200;
                  const severity = isSuccess ? "success" : "error";
                  const summary = isSuccess
                    ? t("vocabulary.toasts.summaries.word_save_success")
                    : t("vocabulary.toasts.summaries.word_save_fail");

                  toast.current.show({
                    severity,
                    summary,
                    detail: response.message,
                  });

                  setWordInput("");
                  setMeaningInput("");

                  updateParentView(!parentViewUpdater);
                } else {
                  toast.current.show({
                    severity: "error",
                    summary: t("vocabulary.toasts.summaries.word_save_fail"),
                    detail: t("vocabulary.toasts.details.word_save_fail"),
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default AddWordPopUp;
