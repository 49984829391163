import React from "react";
import {
  confirmDialog,
  ConfirmDialog,
  createUseStyles,
  useAccessToken,
  Button,
  Card,
  Dialog,
  Divider,
  InputText,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import {
  deleteWordFromPile,
  updateWordInDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";
import UpdateWordDialog from "./UpdateWordDialog";

export default function DraggableCardFooter({
  pileId,
  wordId,
  word,
  meaning,
  updateParentView,
  parentViewUpdater,
}) {
  const [showUpdateWordPopup, setShowUpdateWordPopup] = React.useState(false);

  const { t } = useTranslation();

  const toast = React.useRef<Toast>();

  const token = useAccessToken();

  return (
    <div className="flex justify-content-end">
      <UpdateWordDialog
        wordId={wordId}
        word={word}
        meaning={meaning}
        showUpdateWordPopup={showUpdateWordPopup}
        setShowUpdateWordPopup={setShowUpdateWordPopup}
        parentViewUpdater={parentViewUpdater}
        updateParentView={updateParentView}
        toast={toast}
      />
      <Button
        key="update"
        label={t("vocabulary.inputs.update")}
        onClick={() => {
          setShowUpdateWordPopup(true);
        }}
      />
      <Button
        key="delete"
        label={t("vocabulary.buttons.delete_word")}
        className="p-button-danger ml-3"
        onClick={() => {
          confirmDialog({
            message: t("vocabulary.subtitles.delete_word", { word: word }),
            header: t("vocabulary.titles.delete_word"),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: t("texts.yes"),
            rejectLabel: t("texts.no"),
            accept: async () => {
              const response = await deleteWordFromPile(pileId, wordId);

              if (typeof response === "object") {
                const isSuccess = response.status === 200;
                const severity = isSuccess ? "success" : "error";
                const summary = isSuccess
                  ? t("vocabulary.toasts.summaries.word_delete_success")
                  : t("vocabulary.toasts.summaries.word_delete_fail");

                toast.current.show({
                  severity,
                  summary,
                  detail: response.message,
                });
                updateParentView(!parentViewUpdater);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: t("vocabulary.toasts.summaries.word_delete_fail"),
                  detail: t("vocabulary.toasts.details.word_delete_fail"),
                });
              }
            },
            reject: () => {
              alert("rejected");
            },
          });
        }}
      />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
}
