import React from "react";
// @ts-ignore
import { I18nextProvider } from "react-i18next";
// @ts-ignore
import i18n from "@projectdiction/translations";
import { App } from "./components/App";

import {
  BrowserRouter,
  CheckLoginWrapper,
  // @ts-ignore
} from "@projectdiction/common-react";

export default function Root(props) {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <CheckLoginWrapper>
          <App {...props} />
        </CheckLoginWrapper>
      </BrowserRouter>
    </I18nextProvider>
  );
}
