import {
  NavigationGrid,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function VocabularyMainGrid() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      title={t("vocabulary.titles.vocabulary")}
      data={[
        {
          title: t("vocabulary.titles.dictionary_nav"),
          route: ROUTES.VOCABULARY.DICTIONARY.HOME(),
          description: t("vocabulary.texts.dictionary_nav_description"),
          label: t("vocabulary.buttons.open_dictionary_nav"),
        },
        {
          title: t("vocabulary.titles.dictionary_categories_nav"),
          route: ROUTES.VOCABULARY.CATEGORIES.HOME,
          label: t("vocabulary.buttons.open_dictionary_nav"),
        },
        {
          title: t("vocabulary.titles.copy_dictionary_nav"),
          label: t("vocabulary.buttons.open_dictionary_nav"),
        },
      ]}
    />
  );
}
