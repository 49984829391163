import DraggableCard from "./DraggableCard/DraggableCard";

const DeckList = ({
  pileItems,
  name,
  cardIndex,
  color,
  pileId,
  updateParentView,
  parentViewUpdater,
}) => {
  return (
    <div className="flex flex-wrap h-full w-full justify-content-center">
      {pileItems.map((pileItem, pileIndex) => {
        return (
          <div className="m-2" key={pileIndex}>
            <DraggableCard
              pileItem={pileItem}
              pileIndex={pileIndex}
              pileName={name}
              cardIndex={cardIndex}
              color={color}
              pileId={pileId}
              parentViewUpdater={parentViewUpdater}
              updateParentView={updateParentView}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DeckList;
