import React from "react";

import {
  deleteWordFromDictionary,
  deleteDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  confirmDialog,
  useNavigate,
  Card,
  ConfirmDialog,
  Button,
  InputText,
  Divider,
  Dialog,
  Toast,
  Skeleton,
  ROUTES,
  useAccessToken,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import UpdateWordPopUp from "./molecules/UpdateWordPopUp";
import AddWordPopUp from "./molecules/AddWordPopUp";

export default function DictionaryItem({
  dictionaryId,
  dictionaryItem,
  parentViewUpdater,
  updateParentView,
  toast,
}) {
  const { _id: wordId, word, meaning } = dictionaryItem;
  const token = useAccessToken();
  const { t } = useTranslation();

  const [wordInput, setWordInput] = React.useState("");
  const [meaningInput, setMeaningInput] = React.useState("");
  const [currentWordId, setCurrentWordId] = React.useState<string>();

  const [showAddWordPopup, setShowAddWordPopup] = React.useState(false);
  const [showUpdateWordPopup, _setShowUpdateWordPopup] = React.useState(false);

  const setShowUpdateWordPopup = (val) => {
    _setShowUpdateWordPopup(val);
    // updateParentView(!parentViewUpdater);
  };

  const updateClick = () => {
    setWordInput(word);
    setMeaningInput(meaning);
    setCurrentWordId(wordId);
    setShowUpdateWordPopup(true);
  };

  const deleteClick = () => {
    confirmDialog({
      message: t("vocabulary.subtitles.delete_word", {
        word: word,
      }),
      header: t("vocabulary.titles.delete_word"),
      icon: "pi pi-exclamation-triangle",
      acceptLabel: t("texts.yes"),
      rejectLabel: t("texts.no"),
      accept: async () => {
        let response;
        try {
          response = await deleteWordFromDictionary(token, {
            dictionaryId,
            wordId,
          });

          const isSuccess = response.status === 200;
          const severity = isSuccess ? "success" : "error";
          const summary = isSuccess
            ? t("vocabulary.toasts.summaries.word_delete_success")
            : t("vocabulary.toasts.summaries.word_delete_fail");
          // @ts-ignore
          toast.current.show({
            severity,
            summary,
            detail: response.message,
          });

          updateParentView(!parentViewUpdater);
        } catch (error) {
          // @ts-ignore
          toast.current.show({
            severity: "error",
            summary: t("vocabulary.toasts.summaries.word_delete_fail"),
            detail: error.response.data.error.message,
          });
        }
      },
    });
  };

  return (
    <>
      <Dialog
        header={t("vocabulary.titles.add_to_dictionary")}
        visible={showAddWordPopup}
        style={{
          width: "50vw",
        }}
        onHide={() => setShowAddWordPopup(false)}
      >
        <AddWordPopUp
          dictionaryId={dictionaryId}
          updateParentView={updateParentView}
          parentViewUpdater={parentViewUpdater}
        />
      </Dialog>
      <Dialog
        header={t("vocabulary.titles.update_word")}
        visible={showUpdateWordPopup}
        style={{
          width: "50vw",
        }}
        onHide={() => setShowUpdateWordPopup(false)}
      >
        <UpdateWordPopUp
          dictionaryId={dictionaryId}
          setShowUpdateWordPopup={setShowUpdateWordPopup}
          wordInput={wordInput}
          setWordInput={setWordInput}
          meaningInput={meaningInput}
          setMeaningInput={setMeaningInput}
          currentWordId={currentWordId}
        />
      </Dialog>
      <Card
        pt={{
          root: {
            className: "w-15rem h-15rem m-2 animate__animated animate__fadeIn",
          },
          body: { className: "w-full h-full" },
          content: { className: "w-full h-7rem overflow-scroll" },
          footer: { className: "flex justify-content-end" },
        }}
        title={word}
        footer={
          <>
            <Button
              label={t("vocabulary.inputs.update")}
              onClick={updateClick}
            />
            <Button
              label={t("vocabulary.buttons.delete_word")}
              className="p-button-danger ml-3"
              onClick={deleteClick}
            />
          </>
        }
      >
        {meaning}
      </Card>
    </>
  );
}
