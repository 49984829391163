import React from "react";

import {
  createUseStyles,
  useDrag,
  usePreview,
  Button,
  Card,
  STYLES_CARD,
  STYLES_FLEX_CENTER_CENTER,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  root: {},
  front: {},
  back: {},
  card: {
    "& .p-card-body .p-card-content": {
      ...STYLES_FLEX_CENTER_CENTER,
      fontSize: "2rem",
      height: "100% !important",
    },
    ...STYLES_CARD,
  },
});

const FlipCard = ({
  pileItem,
  pileIndex,
  pileName,
  cardIndex,
  color,
  pileId,
}) => {
  const { word, meaning } = pileItem;

  const classes = useStyles();

  const [cardAnimation, setCardAnimation] = React.useState("");

  const [showBack, setShowBack] = React.useState<boolean>(false);

  const animate = () => {
    setCardAnimation("");
    setTimeout(() => setCardAnimation("animate__flipInX"), 1);
  };

  React.useEffect(() => {
    setShowBack(false);
  }, [pileIndex]);

  const { t } = useTranslation();

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "pileItem",
    item: { pileItem, pileIndex, pileName, cardIndex, pileId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const MyPreview = () => {
    const preview = usePreview();
    if (!preview.display) {
      return null;
    }
    const { item, style } = preview;
    if (item.noPreview) return null;
    return (
      <div>
        <Card
          title={item.pileItem.word}
          subTitle={item.pileItem.meaning}
          style={{
            height: "12rem",
            width: "15rem",
            background: color,
            ...style,
          }}
        />
      </div>
    );
  };

  const cardClassName = `h-full w-full ${classes.card} flex justify-content-center align-items-center`;

  return (
    <>
      <div
        className={`w-full h-full animate__animated ${cardAnimation}`}
        onClick={() => {
          animate();
          setShowBack(!showBack);
          console.log(pileItem);
          console.log(isDragging);
        }}
        ref={drag}
      >
        {showBack ? (
          <Card
            title={t("vocabulary.titles.meaning")}
            className={`${cardClassName} ${classes.back} bg-orange-500`}
          >
            {meaning}
          </Card>
        ) : (
          <Card
            title={t("vocabulary.titles.word")}
            className={`${cardClassName} ${classes.front} bg-cyan-500`}
          >
            {word}
          </Card>
        )}
      </div>
      <MyPreview />
      <div ref={dragPreview} />
    </>
  );
};

export default FlipCard;
