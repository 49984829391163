import React from "react";

import {
  useAccessToken,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";
import DictionaryBrowserMain from "./organism/DictionaryBrowserMain";
import DictionaryBrowserNavSidebar from "./organism/DictionaryBrowserNavSidebar";

const DictionaryBrowserTemplate = ({ dictionaryId }) => {
  const [viewUpdater, updateView] = React.useState<boolean>();

  const [dictionary, setDictionary] = React.useState<any>();

  const token = useAccessToken();

  React.useEffect(() => {
    if (!dictionaryId) return;
    getDictionary(token, { dictionaryId })
      .then((response: any) => {
        setDictionary(response.data.dictionary);
      })
      .catch((error: any) => console.log(error));
  }, [dictionaryId, viewUpdater]);

  if (!dictionary) {
    return (
      <div className={`grid h-full w-full flex flex-row`}>
        <div className="col-3" style={{ height: "80vh" }}>
          <DictionaryBrowserNavSidebar
            dictionaryId={dictionaryId}
            parentViewUpdater={viewUpdater}
            updateParentView={updateView}
          />
        </div>
        <div className={"col-9"} style={{ height: "80vh" }}></div>
      </div>
    );
  }

  const showNavSidebar =
    dictionary.type === "default" ||
    dictionary.type === "user" ||
    dictionary.type === "copy";

  return (
    <div className={`h-full w-full flex flex-row`}>
      {showNavSidebar && (
        <div className="w-3 h-full mr-4" style={{ height: "80vh" }}>
          <DictionaryBrowserNavSidebar
            dictionaryId={dictionaryId}
            parentViewUpdater={viewUpdater}
            updateParentView={updateView}
          />
        </div>
      )}
      <div className={`${showNavSidebar ? "w-9" : "w-12"} h-full`}>
        <DictionaryBrowserMain
          dictionary={dictionary}
          parentViewUpdater={viewUpdater}
          updateParentView={updateView}
        />
      </div>
    </div>
  );
};

export default DictionaryBrowserTemplate;
