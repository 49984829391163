import React from "react";

import {
  Button,
  Card,
  PickList,
  useAccessToken,
  useNavigate,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getDictionary,
  copyDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function DictionaryCopierTemplate({ dictionaryId }) {
  const [dictionary, setDictionary] = React.useState<any>();
  const [items, setItems] = React.useState<any>([]);
  const [copiedItems, setCopiedItems] = React.useState<any>([]);

  const token = useAccessToken();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!dictionaryId) return;
    getDictionary(token, { dictionaryId })
      .then((response: any) => {
        setDictionary(response.data.dictionary);
        setItems(response.data.dictionary.items);
      })
      .catch((error: any) => console.log(error));
  }, [dictionaryId]);

  return (
    <Card
      title="Copy"
      pt={{
        root: { className: "h-full w-full" },
        body: { className: "h-full w-full" },
        content: { className: "h-30rem w-full" },
        footer: { className: "w-full flex justify-content-end" },
      }}
      footer={
        <Button
          onClick={() => {
            const dictionaryItemsIds = copiedItems.map((item: any) => item._id);
            copyDictionary(token, dictionaryId, dictionaryItemsIds).then(
              (response) => {
                navigate(
                  ROUTES.VOCABULARY.DICTIONARY.HOME(
                    response.data.dictionary._id
                  )
                );
              }
            );
          }}
        >
          Copy
        </Button>
      }
    >
      <PickList
        source={items}
        target={copiedItems}
        showSourceControls={false}
        showTargetControls={false}
        sourceHeader="Available Dictionary"
        targetHeader="Your Dictionary"
        pt={{
          list: { className: "flex flex-wrap" },
        }}
        itemTemplate={(item) => {
          return (
            // <div key={item._id}>{item.word}({item.meaning.slice(0, 25)}...)</div>
            <Card
              key={item._id}
              title={item.word}
              subTitle={item.meaning.slice(0, 25)}
              pt={{
                root: "h-12rem w-12rem",
                body: "h-full w-full flex flex-column",
                footer: "w-full flex justify-content-end mt-auto",
              }}
            />
          );
        }}
        onChange={(e) => {
          setItems(e.source);
          setCopiedItems(e.target);
        }}
      />
    </Card>
  );
}
