import React from "react";
import ReactDOM from "react-dom";
import {
  singleSpaReact,
  // @ts-ignore
} from "@projectdiction/common-react";

import Root from "./root.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    console.log(err, info, props);
    return (
      <div>An error occured, message in projectdiction-vocabulary.tsx</div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
