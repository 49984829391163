import React from "react";
import {
  useNavigate,
  Button,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import NewPileDialog from "../NewPileDialog";

export default function StudyCardFooter({
  dictionaryId,
  piles,
  updateParentView,
  parentViewUpdater,
  setCurrentIndices,
  toast,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pileNameInput, setPileNameInput] = React.useState("");

  const [showPopup, setShowPopup] = React.useState(false);

  return (
    <div className="flex justify-content-center">
      <NewPileDialog
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        pileNameInput={pileNameInput}
        setPileNameInput={setPileNameInput}
        dictionaryId={dictionaryId}
        parentViewUpdater={parentViewUpdater}
        updateParentView={updateParentView}
        setCurrentIndices={setCurrentIndices}
        toast={toast}
      />
      <Button
        label={t("vocabulary.buttons.new_pile")}
        onClick={() => {
          setShowPopup(true);
          setPileNameInput("");
        }}
        icon="pi pi-plus"
      />
      <Button
        label={t("vocabulary.buttons.view_dictionary")}
        onClick={() => {
          navigate(ROUTES.VOCABULARY.DICTIONARY.HOME(dictionaryId));
        }}
        className="ml-3"
        icon="pi pi-book"
      />
    </div>
  );
}
