import React from "react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  createUseStyles,
  useAccessToken,
  Button,
  Card,
  ConfirmDialog,
  OverlayPanel,
  Skeleton,
  SwatchesPicker,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  updateDictionary,
  deletePile,
  movePileWord,
  getDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function PileCardFooter({
  pile,
  toast,
  updateParentView,
  parentViewUpdater,
  showList,
  setShowList,
  cardIndex,
  setBackgroundColor,
}) {
  const { t } = useTranslation();
  const overlayPanelReactRef = React.useRef<OverlayPanel>();

  const token = useAccessToken();

  const clickRemove = async () => {
    if (pile.items.length > 0) {
      toast.current.show({
        severity: "error",
        summary: t("vocabulary.toasts.summaries.pile_remove_fail"),
        detail: t("vocabulary.toasts.details.pile_remove_fail"),
      });
      return;
    }

    if (pile.type === "default_pile") {
      toast.current.show({
        severity: "error",
        summary: t("vocabulary.toasts.summaries.default_pile_remove_fail"),
        detail: t("vocabulary.toasts.details.default_pile_remove_fail"),
      });
      return;
    }

    const data = await deletePile(token, {
      pileId: pile._id,
      dictionaryId: pile.parent,
    });

    if (data.status === 400) {
      toast.current.show({
        severity: "error",
        summary: t("vocabulary.toasts.summaries.pile_remove_fail"),
        detail: t("vocabulary.toasts.details.pile_remove_fail"),
      });
    } else {
      updateParentView(!parentViewUpdater);
      toast.current.show({
        severity: "success",
        summary: t("vocabulary.toasts.summaries.pile_remove_success"),
      });
    }
  };

  const setColor = async (val) => {
    const data = await updateDictionary(token, {
      dictionaryId: pile._id,
      color: val,
    });

    if (data.status === 400) {
      toast.current.show({
        severity: "error",
        summary: t("vocabulary.toasts.summaries.set_color_fail"),
      });
    }

    setBackgroundColor(val);

    updateParentView(!parentViewUpdater);
  };

  return (
    <div className="w-full flex justify-content-end align-items-center">
      <Button
        label={
          showList
            ? t("vocabulary.buttons.show_cards")
            : t("vocabulary.buttons.show_list")
        }
        onClick={() => {
          setShowList(!showList);
        }}
      />
      <Button
        className="p-button-danger ml-3"
        label={t("vocabulary.buttons.remove_pile")}
        onClick={clickRemove}
      />
      <Button
        label={t("vocabulary.buttons.change_color")}
        onClick={(e) => overlayPanelReactRef.current.toggle(e)}
        className="ml-3"
      />
      <OverlayPanel ref={overlayPanelReactRef} id={`overlay_panel${cardIndex}`}>
        <SwatchesPicker
          // value={color}
          onChangeComplete={(color) => {
            setColor(color.hex);
            overlayPanelReactRef.current.hide();
          }}
          className="p-button-danger ml-3"
        />
      </OverlayPanel>
    </div>
  );
}
