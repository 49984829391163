import React from "react";
import useStyles from "./NewDictionaryPopUp.style";

import {
  useAccessToken,
  useUserPreferences,
  Button,
  InputText,
  Divider,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { newDictionary } from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";

const NewDictionaryPopUp = ({ updateParentView, parentViewUpdater }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [label, setLabel] = React.useState("");

  const toast = React.useRef<Toast>();

  const token = useAccessToken();

  const userPreferences = useUserPreferences();

  return (
    <>
      <div>
        <div className="fluid">
          <InputText
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            placeholder={t("vocabulary.inputs.label")}
          />
        </div>
        <Divider />
        <div className={classes.buttonRoot}>
          <Button
            label={t("vocabulary.buttons.add_dictionary")}
            onClick={async () => {
              const response = await newDictionary(
                token,
                { label },
                userPreferences?.languages?.studyLanguage
              );

              if (typeof response === "object") {
                const isSuccess = response.status === 200;
                const severity = isSuccess ? "success" : "error";
                const summary = isSuccess
                  ? t("vocabulary.toasts.summaries.dictionary_save_success")
                  : t("vocabulary.toasts.summaries.dictionary_save_fail");

                toast.current.show({
                  severity,
                  summary,
                  detail: response.message,
                });

                setLabel("");

                updateParentView(!parentViewUpdater);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: t(
                    "vocabulary.toasts.summaries.dictionary_save_fail"
                  ),
                  detail: t("vocabulary.toasts.details.dictionary_save_fail"),
                });
              }
            }}
          />
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default NewDictionaryPopUp;
