import React from "react";

import {
  useParams,
  // @ts-ignore
} from "@projectdiction/common-react";

import DictionaryBrowserTemplate from "./template/DictionaryBrowserTemplate";

const DictionaryBrowser = () => {
  const { id } = useParams();
  return <DictionaryBrowserTemplate key={id} dictionaryId={id} />;
};

export default DictionaryBrowser;
