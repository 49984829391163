import React from "react";

import {
  createUseStyles,
  useAccessToken,
  useDrop,
  Button,
  Card,
  ConfirmDialog,
  OverlayPanel,
  Skeleton,
  SwatchesPicker,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

import DeckList from "../Deck/DeckList";
import DeckCards from "../Deck/DeckCards";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  getDictionary,
  movePileWord,
  // @ts-ignore
} from "@projectdiction/api-helper";
import PileCardFooter from "./PileCardFooter";

const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    "& > .p-card-body": {
      height: "100%",
    },
    "& > .p-card-body > .p-card-content": {
      height: "70%",
      overflow: "auto",
    },
  },
});

const PileCard = ({
  name,
  pileId,
  cardIndex,
  updateParentView,
  parentViewUpdater,
}) => {
  const classes = useStyles();

  const toast = React.useRef<Toast>();

  const [showList, setShowList] = React.useState(false);

  const [pile, setPile] = React.useState<any>();

  const [backgroundColor, setBackgroundColor] = React.useState();

  const token = useAccessToken();

  const { t } = useTranslation();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "pileItem",
    drop: async (item) => {
      console.log(item);

      const {
        pileItem,
        pileIndex,
        pileName,
        cardIndex,
        pileId: incomingPileId,
      } = item;

      if (incomingPileId === pileId) {
        return;
      }

      await movePileWord(token, {
        incomingPileId,
        outgoingPileId: pileId,
        wordId: pileItem._id,
      });

      if (!showList) {
        toast.current.show({
          severity: "success",
          summary: t("vocabulary.toasts.summaries.pile_add_success", {
            word: pileItem.word,
            pileName,
          }),
        });
      }
      updateParentView(!parentViewUpdater);

      return item;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  React.useEffect(() => {
    async function fetch() {
      const response = await getDictionary(token, { dictionaryId: pileId });

      const pile = response.data.dictionary;

      setPile(pile);

      setBackgroundColor(pile.color);
    }

    fetch();
  }, [pileId, parentViewUpdater]);

  if (!pile) {
    return (
      <Card
        title={name}
        className={`${classes.card} h-full w-full`}
        footer={<Skeleton />}
      >
        <Skeleton />
      </Card>
    );
  }

  return (
    <div ref={drop} className={`h-full w-full`}>
      <Card
        title={`${pile.label} (${pile.items.length})`}
        className={`${classes.card} h-full w-full ${isOver && "bg-green-200"}`}
        footer={
          <PileCardFooter
            pile={pile}
            parentViewUpdater={parentViewUpdater}
            updateParentView={updateParentView}
            toast={toast}
            setBackgroundColor={setBackgroundColor}
            cardIndex={cardIndex}
            showList={showList}
            setShowList={setShowList}
          />
        }
        style={{ background: backgroundColor }}
      >
        <div className="h-full w-full">
          {showList ? (
            <DeckList
              key={cardIndex}
              pileItems={pile.items}
              name={pile.label}
              cardIndex={cardIndex}
              color={pile.color}
              pileId={pile._id}
              parentViewUpdater={parentViewUpdater}
              updateParentView={updateParentView}
            />
          ) : (
            <DeckCards
              key={cardIndex}
              pileItems={pile.items}
              pileName={pile.label}
              cardIndex={cardIndex}
              color={pile.color}
              pileId={pile._id}
            />
          )}
        </div>
        <Toast ref={toast} />
      </Card>
    </div>
  );
};

export default PileCard;
