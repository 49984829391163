import React from "react";
import {
  createUseStyles,
  Card,
  Button,
  STYLES_FLEX_CENTER_CENTER,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import FlipCard from "./FlipCard";

const useStyles = createUseStyles({
  root: {
    "& .ddcontainer": {
      width: "100%",
      height: "10%",
    },
    "& .ddcontainersource": {
      height: "100%",
      width: "100%",
    },
  },
});

const DeckCards = ({ pileItems, pileName, cardIndex, color, pileId }) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const item = pileItems[currentIndex];

  const buttonContainerClassName =
    "flex h-full align-items-center justify-content-center col-2 flex-column";

  const cannotMoveLeft = () => {
    return currentIndex === 0;
  };

  const cannotMoveRight = () => {
    return currentIndex === pileItems.length - 1;
  };

  const moveLeft = () => {
    if (cannotMoveLeft()) return;
    setCurrentIndex(currentIndex - 1);
  };
  const moveRight = () => {
    if (cannotMoveRight()) return;
    setCurrentIndex(currentIndex + 1);
  };

  if (!item) {
    return null;
  }

  return (
    <div
      className={`flex h-full w-full align-items-center justify-content-center ${classes.root}`}
    >
      <div className={buttonContainerClassName}>
        <Button
          icon="pi pi-arrow-left"
          onClick={moveLeft}
          disabled={cannotMoveLeft()}
        />
      </div>
      <div className="h-full col-8">
        <div className="w-full h-full flex flex-column">
          <FlipCard
            pileItem={item}
            pileIndex={currentIndex}
            pileName={pileName}
            cardIndex={cardIndex}
            color={color}
            pileId={pileId}
          />
        </div>
      </div>
      <div className={buttonContainerClassName}>
        <Button
          icon="pi pi-arrow-right"
          onClick={moveRight}
          disabled={cannotMoveRight()}
        />
      </div>
    </div>
  );
};

export default DeckCards;
