import React from "react";
import {
  confirmDialog,
  createUseStyles,
  useAccessToken,
  Button,
  Card,
  Dialog,
  Divider,
  InputText,
  Toast,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import {
  deleteWordFromPile,
  updateWordInDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function UpdateWordDialog({
  wordId,
  word,
  meaning,
  showUpdateWordPopup,
  setShowUpdateWordPopup,
  parentViewUpdater,
  updateParentView,
  toast,
}) {
  const [wordInput, setWordInput] = React.useState(word);
  const [meaningInput, setMeaningInput] = React.useState(meaning);

  const { t } = useTranslation();

  const token = useAccessToken();

  return (
    <Dialog
      header={t("vocabulary.titles.update_word")}
      visible={showUpdateWordPopup}
      style={{
        width: "50vw",
      }}
      onHide={() => setShowUpdateWordPopup(false)}
    >
      <div>
        <div className="fluid">
          <InputText
            value={wordInput}
            onChange={(e) => setWordInput(e.target.value)}
            placeholder={t("vocabulary.inputs.word")}
          />
          <Divider />
          <InputText
            value={meaningInput}
            onChange={(e) => setMeaningInput(e.target.value)}
            placeholder={t("vocabulary.inputs.meaning")}
          />
        </div>
        <Divider />
        <div>
          <Button
            label={t("vocabulary.buttons.update_word")}
            onClick={async () => {
              const response = await updateWordInDictionary(token, {
                wordId,
                word: wordInput,
                meaning: meaningInput,
              });

              if (typeof response === "object") {
                const isSuccess = response.status === 200;
                const severity = isSuccess ? "success" : "error";
                const summary = isSuccess
                  ? t("vocabulary.toasts.summaries.word_update_success")
                  : t("vocabulary.toasts.summaries.word_update_fail");

                toast.current.show({
                  severity,
                  summary,
                  detail: response.message,
                });

                setWordInput("");
                setMeaningInput("");
                setShowUpdateWordPopup(false);
                updateParentView(!parentViewUpdater);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: t("vocabulary.toasts.summaries.word_update_fail"),
                  detail: t("vocabulary.toasts.details.word_update_fail"),
                });
              }
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
